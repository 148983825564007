<template>
  <div class="hp_footer">
    <div class="max-width">
      <div class="content-box">
        <div class="texts">
          <div class="contact">联系我们</div>
          <div class="eng">CONTACT US</div>
          <div class="address">地址：{{ data.addr }}</div>
          <div class="net">联系方式：{{ data.email }}</div>
          <div class="net">联系电话：{{ data.phone }}</div>
        </div>
        <div class="qrcodes">
           <div>
            <div class="qrcode"><img :src="qrcodes.shipperApp" class="qrCodeImg"/></div>
            <div class="text">货主安卓端</div>
          </div>
           <div>
            <div class="qrcode"><img :src="qrcodes.shipperMiniApp" class="qrCodeImg"/></div>
            <!-- require(`@/assets/tongxiang/platformQR.png`)  require(`@/assets/tongxiang/appQR.png`)-->
            <div class="text">货主小程序端</div>
          </div>
          <div>
            <div class="qrcode"><img :src="qrcodes.driverAndriodApp" class="qrCodeImg"/></div>
            <div class="text">司机安卓端</div>
          </div>
          <div>
            <div class="qrcode"><img :src="qrcodes.driverMiniApp" class="qrCodeImg"/></div>
            <div class="text">司机小程序端</div>
          </div>
        </div>
      </div>
      <div class="links">
        <span>友情链接：</span>
        <a v-for="link in links" :key="link.name" :href="link.url" class="link"> {{ link.name }} </a>
      </div>
      <div class="hp_footer_info">{{ data.webLic }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { companyInfo } from "@/api/tongxiangAPI";

const links = [
  {
    name: "桐乡市人民政府",
    url: "http://www.tx.gov.cn/",
  },
  {
    name: "桐乡市经信局",
    url: "http://www.tx.gov.cn/col/col1588579/",
  },
  {
    name: "百度智能云",
    url: "https://cloud.baidu.com/",
  },
  {
    name: "百度云智教育",
    url: "https://abcxueyuan.baidu.com/#/home",
  },
  {
    name: "爱企查",
    url: "https://aiqicha.baidu.com/",
  },
  {
    name: "爱采购",
    url: "https://b2b.baidu.com/",
  },
  {
    name: "PP飞桨",
    url: "https://www.paddlepaddle.org.cn/",
  },
  {
    name: "省产业大脑",
    url: "https://de-pc.jxt.zj.gov.cn/web-company-pc-v1/index.html",
  },
];

const data = ref({});

const getCompanyInfo = async () => {
  const res = await companyInfo();
  if (res.data.success) {
    data.value = res.data.data;
  }
  console.log(data,'datadatadata')

  if(res.data.data != null && res.data.data.logoFile != null && res.data.data.logoFile.filePath != null){
    var link = document.createElement("link");
              link.type = "image/x-icon";
              link.rel = "shortcut icon";

              link.href = res.data.data.logoFile.filePath;
              document.getElementsByTagName("head")[0].appendChild(link);
  }

};


const props = defineProps({
  qrcodes: {
    type: Object,
    default: () => {}
  },
})

getCompanyInfo();
</script>

<style lang="scss" scoped>
.hp_footer {
  width: 100%;
  background-color: #01102c;
  padding-top: 55px;
}

.hp_footer div {
  color: #fff;
}

.max-width {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.texts {
  .contact {
    font-size: 28px;
    font-weight: 600;
  }
  .eng {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 600;
  }
  .address {
    margin-top: 28px;
    font-size: 14px;
  }
  .net {
    margin-top: 10px;
    font-size: 14px;
  }
}

.qrcodes {
  width: 520px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .qrcode {
    width: 109px;
    height: 109px;
    background-color: #fff;
    .qrCodeImg{
      display: block;
      max-height: 109px;
      max-width: 109px;
      height: auto;
      width: auto;
    }
  }
  .text {
    margin-top: 10px;
    font-size: 14px;
    color: #cad4dd;
    text-align: center;
  }
}

.hp_footer_line {
  background-color: #212b3c;
}

.hp_footer_info {
  margin: 13px 0;
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #c2c2c2;
  line-height: 36px;
  white-space: pre-wrap;
}

.hp_footer_menu {
  width: 700px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
}

.hp_footer_menu:hover {
  cursor: pointer;
}

.hp_footer_menu ul > li {
  padding: 10px 20px;
}

.hp_footer_menu ul > li {
  padding: 10px 20px;
}

.hp_footer_menu ul > li {
  float: left;

  font-size: 16px;
  color: #fff;
}

.hp_footer_menu ul > li > a {
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #c2c2c2;
  line-height: 36px;
}

.hp_footer_menu ul .active {
  border-bottom: 2px solid #4384f1;
}

.links {
  margin-top: 30px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .link {
    text-decoration: none;
    color: #fff;
  }
}

@font-face {
  font-family: ZKGDH;
  src: url("../../font/iconfont_zkgdh.TTF");
}
</style>
