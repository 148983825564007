import Vue from "vue";
import Router from "vue-router";

import index from "./index/index.vue";
import news from "./news.vue";
import newsDetail from "./newsDetail.vue";
import doc from "./doc.vue";
import docDetail from "./docDetail.vue";
import stock from "./stock.vue";
import about from "./about.vue";

Vue.use(Router);

export default new Router({
  routes: [
    { path: "*", redirect: "/" },
    {
      //系统入口页面，
      path: "/",
      name: "index",
      props: true,
      meta: {
        title: "合成纤维大脑首页",
      },
      component: index,
    },
    {
      path: "/news",
      name: "news",
      meta: {
        title: "资讯中心",
      },
      component: news,
    },
    {
      path: "/news/:id",
      name: "newsDetail",
      meta: {
        title: "新闻详情",
      },
      component: newsDetail,
    },
    {
      path: "/doc",
      name: "doc",
      meta: {
        title: "资料中心",
      },
      component: doc,
    },
    {
      path: "/doc/:id",
      name: "docDetail",
      meta: {
        title: "资料详情",
      },
      component: docDetail,
    },
    {
      path: "/stock",
      name: "stock",
      meta: {
        title: "货源大厅",
      },
      component: stock,
    },
    {
      path: "/about",
      name: "about",
      meta: {
        title: "关于我们",
      },
      component: about,
    },
  ],
});
