import Vue from 'vue'

export default {
  //平台车辆数统计
  vhcCounter(){
    return Vue.http.post('api/DatavService/vhcCounter', {})
  },
  //平台服务企业数统计
  shipperCounter(){
    return Vue.http.post('api/DatavService/shipperCounter', {})
  },
  //平台货船统计
  shipCounter(){
    return Vue.http.post('api/DatavService/shipCounter', {})
  },
  //平台各状态车辆数统计
  searchVhc(type) {
    return Vue.http.post('api/DatavService/saveOrder', { type: type})
  },
  //平台交易额累加
  allSales(){
    return Vue.http.post('api/DatavService/allSale', {})
  },
  //今日或者本月交易额
  todaySales(){
    return Vue.http.post('api/DatavService/todaySale', {})
  },
  //车主运费统计
  vhcSaleRank(){
    return Vue.http.post('api/DatavService/vhcSaleRank', {})
  },
  //待提货车辆分布及数量统计
  getDeliVhc(type){
    return Vue.http.post('api/DatavService/getDeliVhc', {type : type})
  },
  //在途车辆车辆分布及数量统计
  getOnwayVhc(type){
    return Vue.http.post('api/DatavService/getOnwayVhc', {type : type})
  },
  //异常车辆分布及数量统计
  getAbnormalVhc(type){
    return Vue.http.post('api/DatavService/getAbnormalVhc', {type : type})
  },
  //卸货车辆分布及数量统计
  getUnloadVhc(type){
    return Vue.http.post('api/DatavService/getUnloadVhc', {type : type})
  },
  //货主交易额排名
  shipperSaleRank(){
    return Vue.http.post('api/DatavService/shipperSaleRank', {})
  },
  //用户运单预警
  orderMonitor(){
    return Vue.http.post('api/DatavService/orderMonitor', {})
  },

  //用户运单预警
  getCustAbnormal(){
    return Vue.http.post('api/DatavService/getCustAbnormal', {})
  },
  //车辆线路
  vhcLine(){
    return Vue.http.post('api/DatavService/vhcLine', {})
  },
  //货主发货地图数据
  shipperDeliver(){
    return Vue.http.post('api/DatavService/shipperDeliver', {})
  },
  //货主发货地图数据
  getVhcMap(type){
    return Vue.http.post('api/DatavService/getVhcMap', {type:type})
  },

  //获取可视化基础配置数据
  getVisualBasicData(){
    return Vue.http.post('api/DatavRuleService/getVisualBasicData', {})
  },

//保存可视化基础配置数据
  saveDatavRules(type,items){
    return Vue.http.post('api/DatavRuleService/saveDatavRules', {type:JSON.stringify(type),items:JSON.stringify(items)})
  },


  //保存产品目标交易额配置
  saveProductTarget(params) {
    return Vue.http.post('api/DatavRuleService/saveProductTarget', {basicConfigJson: JSON.stringify(params)})
  },

  //删除产品目标交易额配置
  deleteProductTarget(params) {
    return Vue.http.post('api/DatavRuleService/deleteProductTarget', {ids: params})
  },

   //产品目标交易额数据
   productSale(){
    return Vue.http.post('api/DatavService/productSale', {})
  },

  //每日报价指数
  shipperRoutePrice(){
    return Vue.http.post('api/DatavService/dayPrice', {})
  },

  //线路车型运费统计
  getRouteSales(){
    return Vue.http.post('api/DatavService/getRouteSales', {})
  },
  
  //获取可视化基础配置数据
  getDefineThemConfig(){
    return Vue.http.post('api/DatavRuleService/getDefineThemConfig', {})
  },


  //保存可视化主题，包括自定义和默认得主题
  saveTheme(custTheme,rules){
    return Vue.http.post('api/DatavRuleService/saveTheme', {custTheme:JSON.stringify(custTheme),rules:JSON.stringify(rules)})
  },


   //保存用户可视化模板
   saveUserTempleteConfig(params) {
    return Vue.http.post('api/DatavRuleService/saveUserTempleteConfig', {templeteJson: JSON.stringify(params)})
  },

  //删除用户可视化模板
  deleteUserTempleteConfig(params) {
    return Vue.http.post('api/DatavRuleService/deleteUserTempleteConfig', {ids: params})
  },

  
  //保存动态数据部分 (结构类似：{"code",value}  )
  saveDynamicData(map){
    return Vue.http.post('api/DatavRuleService/saveDynamicData', {params: JSON.stringify(map)})
  },
  //获取主题配置内容
  fetchTheme(){
    return Vue.http.post('api/DatavRuleService/fetchThemeSettings', {})
  },
  //获取指定配置
  fetchConfig(type){
    return Vue.http.post('api/DatavRuleService/fetchConfig', { type : JSON.stringify(type)})
  },

  //恢复默认的主题配置
  restThemConfig(){
    return Vue.http.post('api/DatavRuleService/restThemConfig', {})
  },
//查询车辆位置轨迹
  listVhcLoc (params) {
    return Vue.http.post('api/DatavService/page', { q: JSON.stringify(params) })
  },


  orderRouteRangeTime(id, start, end) {
    return Vue.http.post('api/DatavService/orderRouteRangeTime', { id: id, beginTime:start, endTime:end })
  },

  getOrderSimple(no) {
    return Vue.http.post('api/DatavService/getOrder', { no: no })
  },

    fetchExpireTime() {
        return Vue.http.post('api/DatavService/fetchExpireTime', {})
    },

  listShipperUnpaidAmount(dateLimit,startTime,endTime,shipper){
    return Vue.http.post('api/DatavService/listShipperUnpaidAmount', {dateLimit:dateLimit,startTime:startTime,endTime:endTime,shipper:shipper})
  },
  listShipperBillDetail(qParams){
    return Vue.http.post('api/DatavService/listShipperBillDetail', {qParams:JSON.stringify(qParams)})
  },
  //水运线路
  getShipHot(){
    return Vue.http.post('api/DatavService/getShipHot', {})
  },
  //水运资源
  getWharf(){
    return Vue.http.post('api/DatavService/getWharf', {})
  },
  //水运资源
  getShipDist(){
    return Vue.http.post('api/DatavService/getShipDist', {})
  },
  //平台统计货物总吨数
  goodCounter(){
    return Vue.http.post('api/DatavService/goodCounter', {})
  },
//发货线路
deliverLine(){
  return Vue.http.post('api/DatavService/deliverLine', {})
},
//销售货物占比
salesGoodsRate(){
  return Vue.http.post('api/DatavService/salesGoodsRate', {})
},
//订单运量变化
orderFreightVol(){
  return Vue.http.post('api/DatavService/orderFreightVol', {})
},
//供应链发运方式（月）
scDespatchWay(){
  return Vue.http.post('api/DatavService/scDespatchWay', {})
},
//订单状态分布（周）
orderStatusSpread(){
  return Vue.http.post('api/DatavService/orderStatusSpread', {})
},
//各种路线成本变化
routeCostChange(){
  return Vue.http.post('api/DatavService/routeCostChange', {})
},

//获取客户价值推算数据
getValueGuessDatas(){
  return Vue.http.post('api/DatavService/getValueGuessDatas', {})
},
//获取全国运力图数据
getTransCap(){
  return Vue.http.post('api/DatavService/getTransCap', {})
},

//获取全国热力图数据
getAllDeliRegion(){
  return Vue.http.post('api/DatavService/getAllDeliRegion', {})
},

  //车辆线路
  getHpVhcLineData(){
    return Vue.http.post('api/DatavService/getHpVhcLineData', {})
  },

   //线路车型运费统计
   getWccyShipperSaleRank(){
    return Vue.http.post('api/DatavService/getWccyShipperSaleRank', {})
  },

  //线路车型运费统计
  getWccyTransport(){
    return Vue.http.post('api/DatavService/getWccyTransport', {})
  },

  //获取发货地货量分布
  getWccyGoodAddr(){
    return Vue.http.post('api/DatavService/getWccyGoodAddr', {})
  },
}
