<template>
  <div>
    <div class="banner">
      <div class="max-1200">
        <div class="title">资讯中心</div>
        <div class="eng">Information Center</div>
      </div>
    </div>
    <div class="content">
      <div class="max-1200">
        <div class="tabs">
          <div class="tab" :class="{ active: selected }" @click="toggle">桐乡动态</div>
          <div class="tab" :class="{ active: !selected }" @click="toggle">通知公告</div>
        </div>
        <div class="container">
          <router-link
            class="news"
            v-for="info in visiableData"
            :key="info.id"
            :to="{
              name: 'newsDetail',
              params: { id: info.id },
              query: { type: selected ? 'activity' : 'announce' },
            }"
          >
            <div class="pic">
              <img :src="info.coverImg || require(`@/assets/tongxiang/${imgUrl}.png`)" />
            </div>
            <div class="news-item">
              <div class="small-title truncate">{{ info.title }}</div>
              <div class="des truncate">{{ info.intro }}</div>
              <div class="time">{{ dateOnly(info.updateTime) }}</div>
            </div>
          </router-link>
        </div>
        <div class="more" v-if="showCount < selectedData.length" @click="addMore">查看更多动态</div>
      </div>
    </div>
  </div>
</template>

<script>
import { activityList, announceList } from "@/api/tongxiangAPI";

import { dateOnly } from "@/utils/utils";

export default {
  name: "News",
  data() {
    return {
      selected: true,
      txData: [],
      announceData: [],
      showCount: 16,
    };
  },
  computed: {
    imgUrl() {
      return this.selected ? "news_1" : "announce";
    },
    selectedData() {
      return this.selected ? this.txData : this.announceData;
    },
    visiableData() {
      return this.selectedData.slice(0, this.showCount);
    },
  },
  methods: {
    dateOnly,
    async getActivityList() {
      const res = await activityList();
      if (res.data.success) {
        this.txData = res.data.data;
      }
    },
    async getAnnounceList() {
      const res = await announceList();
      if (res.data.success) {
        this.announceData = res.data.data;
      }
    },
    toggle() {
      this.selected = !this.selected;
      this.showCount = 16;
    },
    addMore() {
      this.showCount += 16;
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.selected = to.query.tab !== "announce";
      },
      immediate: true,
    },
  },
  created() {
    this.getActivityList();
    this.getAnnounceList();
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.max-1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.more {
  width: 270px;
  height: 44px;
  line-height: 44px;
  border: 1px solid #d8d8d8;
  margin: 30px auto;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  cursor: pointer;
}
.banner {
  width: 100%;
  height: 367px;
  background: url("../assets/tongxiang/news_banner.png") no-repeat center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-size: 48px;
    font-weight: 600;
    color: #fff;
    line-height: 67px;
  }
  .eng {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-top: 6px;
  }
}
.tabs {
  width: 100%;
  display: flex;
  margin-top: 30px;
  .tab {
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 400;
    color: $--color-primary;
    background-color: #f1f2f3;
    cursor: pointer;
    &.active {
      color: #fff;
      background-color: $--color-primary;
    }
  }
}
.container {
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
  .news {
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #e6ebee;
    cursor: pointer;
    .pic {
      width: auto;
      height: 130px;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
    .news-item {
      padding: 26px 20px;
      .small-title {
        height: 21px;
        font-size: 16px;
        font-weight: 400;
      }
      .des {
        height: 16px;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #999;
      }
      .time {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #999;
      }
    }
  }
  .news:hover {
    background: linear-gradient(270deg, #82bffc 0%, #1492ff 100%);
    .small-title {
      color: #fff;
    }
    .des {
      color: #fff;
    }
    .time {
      color: #fff;
    }
  }
}
</style>
