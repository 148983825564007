import { computed, ref } from "vue";

import { testCompany } from "@/api/tongxiangAPI";

/**
 ** 试点企业
 */
export default function useCompany() {
  const validTryTab = ref(true);
  const transCompany = ref([]);
  const stockCompany = ref([]);

  const validCompany = computed(() => {
    const data = validTryTab.value ? transCompany.value : stockCompany.value;
    return data.slice(0, 4);
  });

  const getTransCompany = async () => {
    const res = await testCompany();
    if (res.data.success) {
      transCompany.value = res.data.data;
    }
  };
  const getStockCompany = async () => {
    const res = await testCompany("WAREHOUSE_COMPANY");
    if (res.data.success) {
      stockCompany.value = res.data.data;
    }
  };
  getTransCompany();
  getStockCompany();

  const sliderIndex = ref(0);

  const curCompany = computed(() => {
    if (validCompany.value.length === 0) return {};
    return validCompany.value[sliderIndex.value];
  });

  const handleSlide = (index) => {
    sliderIndex.value = index;
  };

  return {
    validTryTab,
    validCompany,
    curCompany,
    handleSlide,
  };
}
