<template>
  <div>
    <header class="banner" />
    <div class="max-1200 bread">
      <div class="icon" />
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'doc' }">资料中心</el-breadcrumb-item>
        <el-breadcrumb-item class="text-blue">{{ detail.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="doc max-1200">
      <div class="title">{{ detail.title }}</div>
      <div class="one-line">
        <div class="company">{{ detail.shipName }}</div>
        <div class="time">{{ detail.createTime }}</div>
        <div class="address">发表于{{ detail.cityName }}</div>
      </div>
      <div class="news-container">
        <div v-html="detail.content" />
      </div>
      <div class="download">
        <div v-for="file in files" :key="file.filePath" class="btn" @click="jump(file)">{{ file.fileName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { newsDetail } from "@/api/tongxiangAPI";
import aEntityAPI from "@/api/aEntityAPI.js";

export default {
  name: "docDetail",
  data() {
    return {
      detail: {},
    };
  },
  computed: {
    files() {
      if (!this.detail.fileName) return [];
      const urls = this.detail.filePaths.split(",");
      const names = this.detail.fileName.split(",");
      const extensions = this.detail.extension.split(",");
      return urls.map((item, index) => ({
        filePath: item,
        fileName: names[index],
        extension: extensions[index],
      }));
    },
  },
  methods: {
    async getDetail() {
      const res = await newsDetail(this.$route.params.id);
      if (res.data.success) {
        this.detail = res.data.data[0];
      }
    },
    jump(file) {
      aEntityAPI.downloadFilePath(file);
    },
  },
  created() {
    this.getDetail();
  },
};
</script>

<style lang="scss" scoped>
.bread :deep(.el-breadcrumb__inner.is-link) {
  font-weight: 400;
  color: #333;
}
.text-blue :deep(.el-breadcrumb__inner) {
  color: $--color-primary !important;
}
.max-1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.banner {
  width: 100%;
  height: 160px;
  background: url("../assets/tongxiang/doc_detail_banner.png") no-repeat center/cover;
}
.bread {
  padding: 13px 0;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 16px;
  background-color: #fff;
  position: relative;
  bottom: 20px;
  .icon {
    background-color: $--color-primary;
    width: 22px;
    height: 12px;
  }
}
.doc {
  margin-top: 20px;
  .title {
    font-size: 28px;
    font-weight: 600;
    color: #333;
  }
  .one-line {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    div {
      color: #999;
      font-size: 14px;
      line-height: 20px;
    }
    .company {
      color: #052355;
    }
  }
  .news-container {
    margin-top: 20px;
    color: #999;
    font-size: 14px;
  }
  .icon {
    cursor: pointer;
    margin: 70px auto 0;
    width: 23px;
    height: 33px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .download {
    margin-bottom: 120px;
    .btn {
      cursor: pointer;
      width: 100%;
      height: 80px;
      line-height: 80px;
      background-color: #f1f2f3;
      margin: 12px 0;
      font-size: 20px;
      color: $--color-primary;
      text-align: center;
    }
  }
}
</style>
