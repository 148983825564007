<template>
  <div class="ygx_body_index">
    <div id="banner" class="banner">
      <el-carousel trigger="click" height="550px" :autoplay="true">
        <el-carousel-item v-for="slider in sliders" :key="slider.id">
          <div :style="{ backgroundImage: `url(${slider.imageUrl})` }" class="content backdrop" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="ad-back-box">
      <div class="ad max-1200">
        <div class="pic">
          <img :src="require('@/assets/tongxiang/news_icon.png')" />
        </div>
        <div class="title">平台快讯：</div>
        <div class="grey-text des intro">{{ currentInfo.title }}</div>
        <div class="grey-text des time">{{ dateOnly(currentInfo.createTime) }}</div>
        <router-link :to="{ name: 'news' }" class="grey-text right-more">+&nbsp;查看更多</router-link>
        <div class="btns">
          <div class="btn" @click="handleBefore"><img :src="require('@/assets/tongxiang/left_arrow.png')" /></div>
          <div class="btn" @click="handleNext"><img :src="require('@/assets/tongxiang/right_arrow.png')" /></div>
        </div>
      </div>
    </div>

    <div id="big-data" class="big-data-box">
      <div class="max-1200">
        <div class="title">平台大数据</div>
        <div class="eng">Data Platform</div>
        <div class="infos">
          <div class="info" v-for="platform in platforms" :key="platform.name">
            <div class="icon" :class="platform.imgClass">
              <img :src="platform.img" />
            </div>
            <div class="des">{{ platform.name }}</div>
            <div class="num">
              {{ platform.count.toLocaleString("en-US") }}
              <span class="unit">{{ platform.unit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="distribution" class="distribution">
      <div class="max-1200">
        <div class="title">平台业务分布</div>
        <div class="eng">Distribution Business</div>
        <div id="chinaMap" class="map"></div>
      </div>
    </div>

    <div id="sort" class="sort">
      <div class="max-1200">
        <div class="title">企业排行榜</div>
        <div class="eng">The Charts</div>
        <div class="table-container">
          <div class="tabs">
            <div class="tab" :class="{ active: validTableTab }" @click="validTableTab = true">运输企业</div>
            <div class="tab" :class="{ active: !validTableTab }" @click="validTableTab = false">仓储企业</div>
          </div>
          <el-table :data="validData" style="width: 100%" :cell-style="cellStyle">
            <el-table-column label="序号" width="80">
              <template slot-scope="scope">
                <span class="text-blue num trans-x">{{ addZero(scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="企业名称" width="260">
              <template slot-scope="scope">
                <span class="text-blue">{{ scope.row.shipperName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="provinceName" label="省" width="120"> </el-table-column>
            <el-table-column prop="cityName" label="市" width="120"> </el-table-column>
            <el-table-column prop="districtName" label="区/县" width="120"> </el-table-column>
            <el-table-column prop="industry" label="所属产业" width="260"> </el-table-column>
            <el-table-column label="发布时间">
              <template slot-scope="scope">
                <span>{{ dateOnly(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="more"></div>
        </div>
      </div>
    </div>

    <div id="try" class="try">
      <div class="max-1200">
        <div class="title">试点企业</div>
        <div class="eng">Pilot Enterprises</div>
        <div class="tabs">
          <div class="tab" :class="{ active: validTryTab }" @click="validTryTab = true">运输企业</div>
          <div class="tab" :class="{ active: !validTryTab }" @click="validTryTab = false">仓储企业</div>
        </div>
        <el-carousel trigger="click" height="352px" :autoplay="true" @change="handleSlide">
          <el-carousel-item v-for="company in validCompany" :key="company.id">
            <div :style="{ backgroundImage: `url(${company.filePath})` }" class="pic backdrop" />
          </el-carousel-item>
        </el-carousel>
        <div class="links">
          <div class="link">
            <div class="small-title">企业简介</div>
            <el-tooltip effect="dark" :content="curCompany.intro" placement="top">
              <div class="des">{{ firstLetters(curCompany.intro, 80) }}</div>
            </el-tooltip>
          </div>
          <div class="link">
            <div class="small-title">企业背景</div>
            <el-tooltip effect="dark" :content="curCompany.profile" placement="top">
              <div class="des">{{ firstLetters(curCompany.profile, 80) }}</div>
            </el-tooltip>
          </div>
          <div class="link">
            <div class="small-title">试点效果</div>
            <el-tooltip effect="dark" :content="curCompany.content" placement="top">
              <div class="des">{{ firstLetters(curCompany.content, 80) }}</div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div id="info_center" class="info_center">
      <div class="max-1200">
        <div class="title">资讯中心</div>
        <div class="eng">Information Center</div>
        <div class="tabs">
          <div class="tab" :class="{ active: validInfoTab }" @click="validInfoTab = true">桐乡动态</div>
          <div class="tab" :class="{ active: !validInfoTab }" @click="validInfoTab = false">通知公告</div>
        </div>
        <div class="news">
          <router-link
            :to="{
              name: 'newsDetail',
              params: { id: firstInfo.id },
              query: { type: validInfoTab ? 'activity' : 'announce' },
            }"
            class="news-left"
          >
            <div class="pic">
              <img :src="firstInfo.coverImg || require(`@/assets/tongxiang/news.png`)" />
            </div>
            <div class="news-item">
              <div class="one-line">
                <div class="small-title">{{ firstInfo.title }}</div>
                <div class="time">{{ dateOnly(firstInfo.updateTime) }}</div>
              </div>
              <div class="des">{{ firstInfo.intro }}</div>
            </div>
          </router-link>
          <div class="news-right">
            <router-link
              class="news-item"
              v-for="info in otherInfo"
              :key="info.id"
              :to="{
                name: 'newsDetail',
                params: { id: info.id },
                query: { type: validInfoTab ? 'activity' : 'announce' },
              }"
            >
              <div class="one-line">
                <div class="small-title">{{ info.title }}</div>
                <div class="time">{{ dateOnly(info.updateTime) }}</div>
              </div>
              <div class="des">{{ firstLetters(info.intro, 40) }}</div>
            </router-link>
          </div>
        </div>
        <router-link :to="{ name: 'news' }" class="more">+ 更多动态</router-link>
      </div>
    </div>

    <div id="join" class="join">
      <div class="max-1200">
        <div class="des">现在开始申请入驻桐乡智慧物流平台</div>
        <div class="btn" @click="jumpJoin">申请入驻<i class="el-icon-right icon"></i></div>
      </div>
    </div>

    <div id="doc_center" class="doc_center">
      <div class="max-1200">
        <div class="title">资料中心</div>
        <div class="eng">Material Center</div>
        <div class="downloads">
          <div class="download" v-for="doc in first4doc" :key="doc.id">
            <div class="pic">
              <img :src="require('@/assets/tongxiang/word.png')" />
            </div>
            <div class="des">
              <div class="small-title" @click="jump(doc)">{{ doc.title }}</div>
              <div class="time">{{ dateOnly(doc.updateTime) }}</div>
            </div>
            <div class="icon" v-if="doc.rule === '文件'" @click="jump(doc)">
              <img :src="require('@/assets/tongxiang/d_icon.png')" />
            </div>
            <div class="btn" @click="jump(doc)">【{{ doc.rule === "文件" ? "下载" : "文章" }}】</div>
          </div>
        </div>
        <router-link :to="{ name: 'doc' }" class="more">+ 更多动态</router-link>
      </div>
    </div>

    <div id="coop" class="coop">
      <div class="max-1200">
        <div class="title">合作伙伴</div>
        <div class="eng">Cooperative Partner</div>
        <div class="corps">
          <div class="corp" v-for="partner in partnerData" :key="partner.id">
            <div :style="{ backgroundImage: `url(${partner.imageUrl})` }" class="pic backcontain" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

import { boradcastList, dataPlatform, docList, companyRank, coopPartner } from "@/api/tongxiangAPI";
import aEntityAPI from "@/api/aEntityAPI.js";

import { addZero, dateOnly } from "@/utils/utils";

import useCompany from "./useCompany";
import useInfo from "./useInfo";
import useChinaMap from "@/hooks/useChinaMap";

/**
 ** slider
 */
const sliders = ref([]);

const getSliders = async () => {
  const res = await boradcastList();
  sliders.value = res.data.data;
};
getSliders();

/**
 ** 平台大数据
 */
const platforms = ref([
  {
    img: require("@/assets/tongxiang/huozhuguanli.png"),
    imgClass: "huozhuguanli",
    name: "货主累计",
    count: 0,
    unit: "人",
  },
  {
    img: require("@/assets/tongxiang/chengyunshangguanli.png"),
    imgClass: "chengyunshangguanli",
    name: "承运商累计",
    count: 0,
    unit: "人",
  },
  {
    img: require("@/assets/tongxiang/siji.png"),
    imgClass: "siji",
    name: "司机累计",
    count: 0,
    unit: "人",
  },
  {
    img: require("@/assets/tongxiang/jiqiache.png"),
    imgClass: "jiqiache",
    name: "车辆累计",
    count: 0,
    unit: "辆",
  },
  {
    img: require("@/assets/tongxiang/waybill_fill.png"),
    imgClass: "waybill_fill",
    name: "运单数累计",
    count: 0,
    unit: "单",
  },
  {
    img: require("@/assets/tongxiang/totol_trans.png"),
    imgClass: "totol_trans",
    name: "总运量",
    count: 0,
    unit: "吨",
  },
  {
    img: require("@/assets/tongxiang/licheng.png"),
    imgClass: "licheng",
    name: "运输里程",
    count: 0,
    unit: "公里",
  },
  {
    img: require("@/assets/tongxiang/balance.png"),
    imgClass: "balance",
    name: "交易额",
    count: 0,
    unit: "万",
  },
]);

const getPlatForm = async () => {
  const res = await dataPlatform();
  if (res.data.success) {
    const d = res.data.data;
    const data = [d.makingCount, d.logistCount, d.driverCount, d.vhcCount, d.ordAmt, d.weight, d.mileage, Math.round(d.charge / 10000)];
    platforms.value = platforms.value.map((item, index) => {
      item.count = data[index];
      return item;
    });
  }
};
getPlatForm();

/**
 ** 可视化地图
 */
useChinaMap("chinaMap");

/**
 ** 排行榜表格
 */
const validTableTab = ref(true);
const transData = ref([]);
const stockData = ref([]);

const validData = computed(() => {
  const data = validTableTab.value ? transData.value : stockData.value;
  return data.slice(0, 10);
});

const getTransData = async () => {
  const res = await companyRank();
  if (res.data.success) {
    transData.value = res.data.data;
  }
};
const getStockData = async () => {
  const res = await companyRank("WAREHOUSE_COMPANY");
  if (res.data.success) {
    stockData.value = res.data.data;
  }
};
getTransData();
getStockData();

const cellStyle = () => ({ height: "50px" });

const firstLetters = (str, num) => {
  if (str?.length > num) {
    return str.slice(0, num) + "...";
  }
  return str;
};

/**
 ** 试点企业
 */
const { validTryTab, validCompany, curCompany, handleSlide } = useCompany();

/**
 ** 资讯中心
 */
const { validInfoTab, firstInfo, otherInfo, currentInfo, handleBefore, handleNext } = useInfo();

/**
 ** 申请入驻
 */
const jumpJoin = () => {
  window.open(
    window.location.protocol +
      "//" +
      window.location.host +
      "/common.html#/shipperAuth?menuTitle=%E4%BC%81%E4%B8%9A%E8%AE%A4%E8%AF%81",
  );
};

/**
 ** 资料中心
 */
const docData = ref([]);

const first4doc = computed(() => {
  if (docData.value.length) return docData.value.slice(0, 4);
  return [];
});

const getDocList = async () => {
  const res = await docList();
  if (res.data.success) {
    docData.value = res.data.data;
  }
};
getDocList();

/**
 ** 合作伙伴
 */
const partnerData = ref([]);

const getPartner = async () => {
  const res = await coopPartner();
  if (res.data.success) {
    partnerData.value = res.data.data;
  }
};
getPartner();
</script>

<script>
export default {
  /**
   ** 资料中心 下载
   */
  methods: {
    download(filePath, fileName, extension) {
      const file = {
        filePath,
        fileName,
        extension,
      };
      aEntityAPI.downloadFilePath(file);
    },
    jump(doc) {
      if (doc.rule === "文件") {
        const urls = doc.filePaths.split(",");
        const names = doc.fileName.split(",");
        const extensions = doc.extension.split(",");
        urls.forEach((url, index) => {
          this.download(url, names[index], extensions[index]);
        });
      } else {
        this.$router.push({
          name: "docDetail",
          params: {
            id: doc.id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./style.scss"></style>
