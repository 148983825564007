<template>
  <div class="stock">
    <div class="banner">
      <div class="max-1200">
        <div class="title">货源大厅</div>
        <div class="eng">Order Introduction</div>
      </div>
    </div>
    <div id="sort" class="sort">
      <div class="max-1200">
        <div class="title">竞价货源</div>
        <div class="eng">Bidding Source</div>
        <div class="table-container">
          <el-table :data="bidSource" style="width: 100%" :cell-style="cellStyle">
            <el-table-column label="序号" width="100">
              <template slot-scope="scope">
                <span class="text-blue num">{{ addZero(scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="起运地" width="130">
              <template slot-scope="scope">
                <span class="text-blue">{{ scope.row.addrRegionName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="目的地" width="130">
              <template slot-scope="scope">
                <span class="text-yellow">{{ scope.row.unaddrRegionName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="货物类型" width="300">
              <template slot-scope="scope">
                <span>{{ scope.row.goodsTypes }}</span>
              </template>
            </el-table-column>
            <el-table-column label="吨位" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.weight ? scope.row.weight : "0" }}吨</span>
              </template>
            </el-table-column>
            <el-table-column label="方数" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.volume  ? scope.row.volume : "0" }}方</span>
              </template>
            </el-table-column>
            <el-table-column label="价格" width="120">
              <template slot-scope="scope">
                <span>-</span>
              </template>
            </el-table-column>
            <el-table-column width="120">
              <template slot-scope="scope">
                <el-button size="medium" type="warning" @click="jump(scope.row)">竞 价</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div id="grab" class="grab">
      <div class="max-1200">
        <div class="title">抢单货源</div>
        <div class="eng">Grabbing Orders</div>
        <div class="table-container">
          <el-table :data="grabSource" style="width: 100%" :cell-style="cellStyle">
            <el-table-column label="序号" width="100">
              <template slot-scope="scope">
                <span class="text-blue num">{{ addZero(scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="起运地" width="130">
              <template slot-scope="scope">
                <span class="text-blue">{{ scope.row.addrRegionName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="目的地" width="130">
              <template slot-scope="scope">
                <span class="text-yellow">{{ scope.row.unaddrRegionName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="货物类型" width="300">
              <template slot-scope="scope">
                <span>{{ scope.row.goodsTypes }}</span>
              </template>
            </el-table-column>
            <el-table-column label="吨位" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.weight ? scope.row.weight : "0" }}吨</span>
              </template>
            </el-table-column>
            <el-table-column label="方数" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.volume  ? scope.row.volume : "0" }}方</span>
              </template>
            </el-table-column>
            <el-table-column label="价格" width="120">
              <template slot-scope="scope">
                <span>***</span>
              </template>
            </el-table-column>
            <el-table-column width="120">
              <template slot-scope="scope">
                <el-button size="medium" type="danger" @click="jump(scope.row)">抢 单</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div id="resource" class="resource">
      <div class="max-1200">
        <div class="title">运力资源</div>
        <div class="eng">Transportation Resources</div>
        <div class="table-container">
          <el-table
            :data="transSource"
            style="width: 100%"
            header-cell-class-name="table_header"
            :cell-style="cellStyle"
          >
            <el-table-column label="序号" width="80">
              <template slot-scope="scope">
                <span class="text-blue num">{{ addZero(scope.$index + 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="承运商" width="300">
              <template slot-scope="scope">
                <span class="text-blue">{{ scope.row.shipperName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="优势线路" width="240">
              <template slot-scope="scope">
                <span class="text-yellow truncate" v-if=" scope.row.shortAddress">{{ scope.row.shortAddress }}</span>
                <span class="text-yellow truncate" v-else>{{ scope.row.shipperRegionAddress }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="shipperRegionCount" label="线路个数" width="130"></el-table-column>
            <el-table-column label="好评率" width="130">
              <template slot-scope="scope">
                <span v-if="scope.row.avgScore && scope.row.avgScore <= 100">{{ scope.row.avgScore }}%</span>
                <span v-else-if="scope.row.avgScore && scope.row.avgScore > 100">100%</span>
              </template>
            </el-table-column>
            <el-table-column label="承运单数" width="130">
              <template slot-scope="scope">
                <span>{{ scope.row.tdoCount }}个</span>
              </template>
            </el-table-column>
            <el-table-column label="注册时间" width="130">
              <template slot-scope="scope">
                <span>{{ dateOnly(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import ElementUI from "element-ui";

import { dateOnly } from "@/utils/utils";
import ls from "@/utils/ls.js";

import { sourceList, transSourceList } from "@/api/tongxiangAPI";

// 竞价货源
const bidSource = ref([]);

const getBidSource = async () => {
  const res = await sourceList("3");
  if (res.data.success) {
    bidSource.value = res.data.data.slice(0, 10);
  }
};
getBidSource();

// 抢单货源
const grabSource = ref([]);

const getGrabSource = async () => {
  const res = await sourceList("5");
  if (res.data.success) {
    grabSource.value = res.data.data.slice(0, 10);
  }
};
getGrabSource();

// 竞价, 抢单跳转
const jump = (company) => {
  const shipper = ls.get("shipper");
  // 检查登录状态
  if (shipper) {
    const code = shipper.companyType.code;
    if (code === "LOGIST_COMPANY" || code === "PERSON") {
      window.location.href = `tms.html#/tmsBidSignOrderInfo/${company.id}?no=${company.no}`;
    } else {
      ElementUI.Message({
        showClose: true,
        message: "企业类型不匹配，限制报价",
        type: "error",
      });
    }
  } else {
    window.location.href = `tms.html#/tmsBidSignOrderInfo/${company.id}?no=${company.no}`;
  }
};

// 运力资源
const transSource = ref([]);

const getTransSource = async () => {
  const res = await transSourceList();
  if (res.data.success) {
    transSource.value = res.data.data.slice(0, 10);
  }
};
getTransSource();

// 表格 style 函数
const addZero = (num) => {
  return num < 10 ? "0" + num : num;
};

const cellStyle = () => ({ height: "50px" });
</script>

<style lang="scss" scoped>
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-blue {
  color: $--color-primary;
}
.text-yellow {
  color: #f1960d;
}
.max-1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 28px;
  font-weight: 600;
  color: #000;
  text-align: center;
}
.eng {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #999;
  text-align: center;
}
.table-container {
  background-color: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  margin-top: 42px;
  padding: 26px;
}
.banner {
  width: 100%;
  height: 367px;
  background: url("../assets/tongxiang/stock_banner.png") no-repeat center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-size: 48px;
    font-weight: 600;
    color: #fff;
    line-height: 67px;
    text-align: left;
  }
  .eng {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-top: 6px;
    text-align: left;
  }
}
.sort {
  /**
  * TODO temporary 
  */
  background-color: #f2f4fa;
  width: 100%;
  padding: 80px 0;
  box-sizing: border-box;
}
.grab {
  background-color: #fff;
  background: url("../assets/tongxiang/stock_back.png") no-repeat top/contain;
  width: 100%;
  padding: 80px 0;
  box-sizing: border-box;
  .title {
    color: #fff;
  }
  .eng {
    color: #fff;
  }
}
.resource {
  background-color: #f2f4fa;
  width: 100%;
  padding: 80px 0;
  box-sizing: border-box;
}
/**
** reset element style  
*/
.stock :deep(.el-table .cell) {
  font-size: 14px;
}
.stock :deep(.el-button--medium) {
  font-size: 16px;
  padding: 7px 28px;
  border-radius: 4px;
}
.stock :deep(.table_header) {
  background-color: #f1f2f3;
  height: 50px;
}
</style>
