<template>
  <div>
    <div class="banner">
      <div class="max-1200">
        <div class="title">资料中心</div>
        <div class="eng">Material Center</div>
      </div>
    </div>
    <div id="doc_center" class="doc_center">
      <div class="max-1200">
        <div class="downloads">
          <div class="download" v-for="doc in first6doc" :key="doc.id">
            <div class="pic" v-if="doc.rule === '文件'">
              <img :src="require('@/assets/tongxiang/word.png')" />
            </div>
            <div class="des">
              <div class="small-title" @click="jump(doc)">{{ doc.title }}</div>
              <div class="time">{{ dateOnly(doc.updateTime) }}</div>
            </div>
            <div class="icon" v-if="doc.rule === '文件'" @click="jump(doc)">
              <img :src="require('@/assets/tongxiang/d_icon.png')" />
            </div>
            <div class="btn" @click="jump(doc)">【{{ doc.rule === "文件" ? "下载" : "文章" }}】</div>
          </div>
        </div>
        <!-- <div class="more">+ 更多动态</div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

import { docList } from "@/api/tongxiangAPI";

import { dateOnly } from "@/utils/utils";

const docData = ref([]);

const first6doc = computed(() => {
  if (docData.value.length) return docData.value.slice(0, 6);
  return [];
});

const getDocList = async () => {
  const res = await docList();
  if (res.data.success) {
    docData.value = res.data.data;
  }
};
getDocList();
</script>

<script>
import aEntityAPI from "@/api/aEntityAPI.js";
export default {
  methods: {
    download(filePath, fileName, extension) {
      const file = {
        filePath,
        fileName,
        extension,
      };
      aEntityAPI.downloadFilePath(file);
    },
    jump(doc) {
      if (doc.rule === "文件") {
        const urls = doc.filePaths.split(",");
        const names = doc.fileName.split(",");
        const extensions = doc.extension.split(",");
        urls.forEach((url, index) => {
          this.download(url, names[index], extensions[index]);
        });
      } else {
        this.$router.push({
          name: "docDetail",
          params: {
            id: doc.id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.max-1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.banner {
  width: 100%;
  height: 367px;
  background: url("../assets/tongxiang/news_banner.png") no-repeat center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-size: 48px;
    font-weight: 600;
    color: #fff;
    line-height: 67px;
  }
  .eng {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-top: 6px;
  }
}
.doc_center {
  width: 100%;
  padding: 80px 0;
  .downloads {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
    .download {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border: 1px solid #e6ebee;
      padding: 30px;
      display: flex;
      position: relative;
      .pic {
        width: 36px;
        height: 45px;
        margin-right: 36px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .des {
        .small-title {
          font-size: 16px;
          font-weight: 400;
          color: #333;
          line-height: 22px;
          width: 336px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
        .time {
          margin-top: 30px;
          font-size: 14px;
          font-weight: 400;
          color: #999;
          line-height: 20px;
        }
      }
      .icon {
        position: absolute;
        right: 30px;
        top: 17px;
        width: 26px;
        height: 26px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .btn {
        position: absolute;
        right: 30px;
        bottom: 30px;
        color: $--color-primary;
        cursor: pointer;
      }
    }
  }
  .more {
    margin: 50px 0 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #999;
    cursor: pointer;
  }
}
</style>
