import Vue from "vue";
import hPage from "./hPageApp.vue";
import router from "./hPageRoute.js";
import store from "@/store";
import ElementUI from "element-ui";
import Box from "@/components/box/box.vue";
import http from "@/plugins/http/http";

import "@/style/theme/index.css";
import "@/style/index.scss";
import "@/style/siyuan.less"; //引入思源字体

import "@/utils/xmlHttpRequestHook.js";

Vue.use(ElementUI, {
  size: "mini", // medium / small / mini
}); // size用于改变组件的默认尺寸，zIndex 设置弹框的初始 z-index（默认值：2000）

Vue.config.productionTip = false;
const HOST = window.location.protocol + "//" + window.location.host;
Vue.use(http, {
  baseURL: HOST + process.env.VUE_APP_BACKEND_URL_PROXY,
});

Vue.component("Box", Box); //div box
//是否开启 日志记录
Vue.prototype._LOGGGER = false;

Vue.prototype.__ftpPath = "";

window.__GetReleaseInfo = function () {
  console.info("release mode: " + process.env.VUE_APP_MODE);
  console.info("release version: " + process.env.VUE_APP_VERSION);
  console.info("relase time: " + process.env.VUE_APP_RELEASE_TIME);
  return true;
};

router.afterEach((to) => {
  window.document.title = to.meta.title;
});

new Vue({
  router,
  store,
  render: (h) => h(hPage),
}).$mount("#app");
