<template>
  <div>
    <header class="banner" />
    <div class="max-1200 bread">
      <div class="icon" />
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ name: 'news' }">资讯中心</el-breadcrumb-item>
        <el-breadcrumb-item class="text-blue">{{ detail.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="news max-1200 grid">
      <div class="main-part">
        <div class="title">{{ detail.title }}</div>
        <div class="one-line">
          <div class="company">{{ detail.shipName }}</div>
          <div class="time">{{ detail.createTime }}</div>
          <div class="address">发表于{{ detail.cityName }}</div>
        </div>
        <div class="news-container">
          <div v-html="detail.content" />
        </div>
      </div>
      <div class="side">
        <div class="title">相关阅读</div>
        <div class="decorate_line" />
        <div class="item-box" v-for="info in sideList" :key="info.id" @click="jump(info.id)">
          <div class="thumbnail">
            <img :src="info.coverImg || require(`@/assets/tongxiang/${imgUrl}.png`)" />
          </div>
          <li class="text">{{ info.title }}</li>
          <div class="time">{{ dateOnly(info.updateTime) }}</div>
        </div>
      </div>
    </div>
    <footer class="bottom">
      <div class="max-1200 btns">
        <div class="btn" v-if="before" @click="jump(before.id)">
          <i class="el-icon-back" />
          <div>上一篇：</div>
          <div class="text">{{ before.title }}</div>
        </div>
        <div class="btn" v-if="after" @click="jump(after.id)">
          <div class="text">{{ after.title }}</div>
          <div>下一篇：</div>
          <i class="el-icon-right" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { activityList, announceList, newsDetail } from "@/api/tongxiangAPI";

import { dateOnly } from "@/utils/utils";

export default {
  name: "newsDetail",
  data() {
    return {
      detail: {},
      list: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    type() {
      return this.$route.query.type === "announce" ? "announce" : "activity";
    },
    imgUrl() {
      return this.$route.query.type === "announce" ? "announce" : "news_1";
    },
    sideList() {
      return this.list.slice(0, 5);
    },
    before() {
      const index = this.list.findIndex((item) => item.id === this.id);
      return this.list[index - 1];
    },
    after() {
      const index = this.list.findIndex((item) => item.id === this.id);
      return this.list[index + 1];
    },
  },
  methods: {
    dateOnly,
    async getNewsDetail() {
      const res = await newsDetail(this.id);
      if (res.data.success) {
        this.detail = res.data.data[0];
      }
    },
    async getActivityList() {
      const res = await activityList();
      if (res.data.success) {
        this.list = res.data.data;
      }
    },
    async getAnnounceList() {
      const res = await announceList();
      if (res.data.success) {
        this.list = res.data.data;
      }
    },
    jump(id) {
      this.$router.push({
        name: "newsDetail",
        params: { id },
        query: { type: this.type },
      });
    },
  },
  created() {
    this.getNewsDetail();
    if (this.type === "announce") {
      this.getAnnounceList();
    } else {
      this.getActivityList();
    }
  },
  watch: {
    id() {
      this.getNewsDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
.bread :deep(.el-breadcrumb__inner.is-link) {
  font-weight: 400;
  color: #333;
}
.text-blue :deep(.el-breadcrumb__inner) {
  color: $--color-primary !important;
}
.max-1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 280px;
  gap: 45px;
}
.banner {
  width: 100%;
  height: 160px;
  background: url("../assets/tongxiang/news_detail.png") no-repeat center/cover;
}
.bread {
  padding: 13px 0;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 16px;
  background-color: #fff;
  position: relative;
  bottom: 20px;
  .icon {
    background-color: $--color-primary;
    width: 22px;
    height: 12px;
  }
}
.news {
  padding: 60px 0;
  .main-part {
    overflow: hidden;
    .title {
      font-size: 28px;
      font-weight: 600;
      color: #333;
    }
    .one-line {
      margin-top: 20px;
      display: flex;
      gap: 20px;
      div {
        color: #999;
        font-size: 14px;
        line-height: 20px;
      }
      .company {
        color: #052355;
      }
    }
    .news-container {
      margin-top: 20px;
      color: #999;
      font-size: 14px;
    }
  }
  .side {
    padding: 27px 15px;
    border: 1px solid #d8d8d8;
    .title {
      font-size: 20px;
      color: #333;
    }
    .decorate_line {
      margin: 30px 5px 0;
      width: 95%;
      height: 1px;
      border-bottom: 1px dashed #d8d8d8;
    }
    .item-box {
      cursor: pointer;
      .thumbnail {
        margin-top: 20px;
        width: auto;
        height: 110px;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
        }
      }
      .text {
        margin-top: 10px;
        color: #333;
        font-size: 16px;
        list-style-type: disc;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .time {
        margin-top: 10px;
        color: #999;
        font-size: 14px;
      }
    }
    .item-box:hover {
      .text {
        color: $--color-primary;
      }
    }
  }
}
.bottom {
  padding: 60px 0;
  background: #f1f2f3;
  .btns {
    display: flex;
    justify-content: center;
    gap: 10px;
    .btn {
      cursor: pointer;
      width: 452px;
      height: 80px;
      line-height: 80px;
      padding: 0 60px;
      box-sizing: border-box;
      background-color: #fff;
      color: #999;
      display: flex;
      align-content: center;
      justify-content: space-between;
      div {
        font-size: 14px;
        color: #999;
      }
      .text {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      i {
        line-height: 80px;
        font-size: 18px;
      }
    }
    .btn:hover {
      background: linear-gradient(270deg, #82bffc 0%, #1492ff 100%);
      div {
        color: #fff;
      }
      i {
        color: #fff;
      }
    }
  }
}

.news :deep(table td),
.news :deep(table th) {
  border-bottom: 1px solid #ccc;
  border-bottom-color: rgb(204, 204, 204);
  border-right: 1px solid #ccc;
  border-right-color: rgb(204, 204, 204);
  padding: 3px 5px;
  min-height: 30px;
  height: 30px;
}
</style>
