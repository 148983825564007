import { computed, ref } from "vue";

import { activityList, announceList } from "@/api/tongxiangAPI";

export default function useInfo() {
  /**
   ** 资讯中心
   */
  const validInfoTab = ref(true);
  const txData = ref([]);
  const announceData = ref([]);

  const firstInfo = computed(() => {
    if (validInfoTab.value) {
      if (txData.value.length) return txData.value.slice(0, 1)[0];
    } else {
      if (announceData.value.length) return announceData.value.slice(0, 1)[0];
    }
    return {};
  });
  const otherInfo = computed(() => {
    if (validInfoTab.value) {
      if (txData.value.length) return txData.value.slice(1, 6);
    } else {
      if (announceData.value.length) return announceData.value.slice(1, 6);
    }
    return {};
  });

  const getNewsList = async () => {
    const res = await activityList();
    if (res.data.success) {
      txData.value = res.data.data;
      currentId.value = txData.value[0].id;
    }
  };
  getNewsList();

  const getAnnounceList = async () => {
    const res = await announceList();
    if (res.data.success) {
      announceData.value = res.data.data;
    }
  };
  getAnnounceList();

  /**
   ** 横条资讯
   */
  const currentId = ref("");

  const currentInfo = computed(() => {
    if (txData.value.length) return txData.value.find((item) => item.id === currentId.value);
    return {};
  });

  const handleBefore = () => {
    const index = txData.value.findIndex((item) => item.id === currentId.value);
    if (index > 0) currentId.value = txData.value[index - 1].id;
  };

  const handleNext = () => {
    const index = txData.value.findIndex((item) => item.id === currentId.value);
    if (index < txData.value.length - 1) currentId.value = txData.value[index + 1].id;
  };

  return {
    validInfoTab,
    firstInfo,
    otherInfo,
    currentInfo,
    handleBefore,
    handleNext,
  };
}
