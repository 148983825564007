<template>
  <div class="hp_body">
    <div id="hp_header" :class="topIndex == 1 || topIndex == 3 ? 'hp_header_white' : 'hp_header_clean'">
      <div class="hp_head_body_left">
        <div class="hp_logo" @click="clickHomeMenum('#hp_home', 0)">
          <img src="../assets/tongxiang/logoTX.png" />
        </div>
        <div class="side-title">智慧物流平台</div>
      </div>
      <div class="home_header_right">
        <div class="tapCss tapCss_Choose" >首页</div>
        <div class="tapCss_other" style="margin-left:40px" @click="jumpPage()">运力共享</div>
        <div class="tapCss_other" @click="toLoginWms">WMS仓储管理系统</div>
        <div class="tapCss_other" @click="toLoginTms">TMS运输管理系统</div>
      </div>
      <!-- <div class="hp_menu_btn" v-if="user != null && user.id != null">
        <div class="login_class_a text-size" @click="landInAction()">进入系统</div>
        <div class="login_class_b text-size" @click="logoutAction()">退出</div>
      </div>
      <div class="hp_menu_btn" v-else>
        <div class="login_class_a text-size" @click="loginAction()">登录</div>
      </div> -->
    </div>
    <div class="hp_menu">
      <div class="max-1200">
        <el-menu
          background-color="#1492ff"
          text-color="#fff"
          active-text-color="#fff"
          :default-active="activeTab"
          mode="horizontal"
          router
        >
          <el-menu-item index="/">简介</el-menu-item>
          <el-submenu index="/news?tab=activity">
            <template slot="title">资讯中心</template>
            <el-menu-item index="/news?tab=activity">桐乡动态</el-menu-item>
            <el-menu-item index="/news?tab=announce">通知公告</el-menu-item>
          </el-submenu>
          <el-menu-item index="/doc">资料中心</el-menu-item>
          <el-menu-item index="/stock">货源大厅</el-menu-item>
          <el-menu-item index="/about">关于我们</el-menu-item>
        </el-menu>
      </div>
    </div>

    <div class="hp_container" :style="showMeunTag ? 'overflow: hidden;' : 'overflow: auto;'">
      <router-view @indexChange="topIndexChange" :topIndex="topIndex"></router-view>
    </div>

    <Footer :qrcodes="qrcodes" />
  </div>
</template>

<script>
import aEntityAPI from "@/api/sysAPI.js";
import { mapActions } from "vuex";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Footer,
  },
  data() {
    return {
      qrcodes:{
        driverMiniApp:null,
        shipperMiniApp:null,
        driverAndriodApp:null,
        shipperApp:null,
      },
      topIndex: 0,
      fullScreenH: 0,
      fullScreenW: 0,
      yearTag: "2021",
      showMeunTag: true,
      user: null,
      codeList: [],
      codeTms: false,
      codeWms: false,
    };
  },
  computed: {
    activeTab() {
      return this.$route.path;
    },
  },
  methods: {
    ...mapActions(["user/logout"]),
    topIndexChange(index) {
      this.topIndex = parseInt(index) - 1;
    },
    //跳转运力共享
    jumpPage(){
      window.location.href = "ylPage.html#/ylIndex";
    },
    clickHomeMenum(selector, index) {
      let that = this;

      if (that.showMeunTag == false) {
        var url = window.location.protocol + "//" + window.location.host + "/hPage.html#/hPage?index=" + index;
        window.location.href = url;
        that.showMeunTag = true;
        window.location.reload(true);
      } else {
        that.topIndex = index;
      }
    },

    //获取云工享企业新闻列表
    getCompanyInfo() {
      let that = this;
      aEntityAPI.request("HomePageConfigService", "getCompanyInfo").then((res) => {
        if (res.data.success) {
          const dataDict = res.data.data;
          
          if (dataDict.qrCode != null ){
            this.qrcodes = dataDict.qrCode;
          }

          if (dataDict.user != null && dataDict.user.id != null) {
            that.user = dataDict.user;
          }
        }
      });
    },

    logoutAction() {
      this["user/logout"]().then((data) => {
        if (data) {
          window.location.href = "/login.html";
        }
      });
    },

    //注册
    regAction() {
      window.location.href = "/login.html#/reg";
    },

    //进入系统
    landInAction() {
      window.location.href = "/login.html#/login";
    },

    //登录
    loginAction() {
      window.location.href = "/login.html#/login";
    },
    toLoginWms() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      if (userInfo != null && userInfo.id != null) {
        if (this.codeWms) {
          window.location.href = "wms.html#/home";
        } else {
          this.$message({
            message: '当前用户暂无wms权限',
            type: 'warning'
          });
        }
      } else {
        if(window.location.hostname=='dulogistics.baiyihulian.com'){
            localStorage.setItem("loginSystem",'wms');
            window.location.href = 'https://enterprise.baiyihulian.com/api/v1/enterprise/v1/api/jumpToApp?appId=068f18ebfa304f4c9e68f9331552bad0'
        }else{
          this.loginAction()
        }
      }
    },
    toLoginTms() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"))
      if (userInfo != null && userInfo.id != null) {
        if (this.codeTms) {
          window.location.href = "tms.html#/home";
        } else {
          this.$message({
            message: '当前用户暂无tms权限',
            type: 'warning'
          });
        }
      } else {
        if(window.location.hostname=='dulogistics.baiyihulian.com'){
            localStorage.setItem("loginSystem",'tms');
            window.location.href = 'https://enterprise.baiyihulian.com/api/v1/enterprise/v1/api/jumpToApp?appId=068f18ebfa304f4c9e68f9331552bad0'
        }else{
          this.loginAction()
        }
      }
    },
     getShipperProduct() {
      let that = this
      let shipper = JSON.parse(localStorage.getItem("shipper"))
      if (shipper != null && shipper.id != null) {
        aEntityAPI.request("ShipperService", "getShipperProducts", { shipperId: shipper.id }).then((res) => {
          if (res.data.success) {
            let list = []
            let arr = res.data.data
            arr.forEach(element => {
              if (element.code == 'wms' && element.status == 3) {
                list.push(element)
                that.codeWms = true
              }
              if (element.code == 'tms' && element.status == 3) {
                list.push(element)
                that.codeTms = true
              }
            });
            that.codeList = list
          }
        });
      }
    },
  },

  mounted() {
    var that = this;
    this.fullScreenH = document.documentElement.clientHeight;
    this.fullScreenW = document.documentElement.clientWidth;
    that.getCompanyInfo();

    if (that.$route.path != null && that.$route.path != "/hPage") {
      that.showMeunTag = false;
      that.topIndex = 6;
    } else {
      if (that.$route.query != null && that.$route.query.index != null) {
        setTimeout(() => {
          that.topIndex = that.$route.query.index;
        }, 1000);
      }
      that.showMeunTag = true;
    }

    var dateDict = new Date();
    this.yearTag = dateDict.getFullYear();
    this.getShipperProduct()
  },
};
</script>

<style lang="scss">
.home_header_right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tapCss_other {
  margin-left: 40px;
  font-size: 20px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  padding-bottom: 3px;
  cursor: pointer;
}

.tapCss_other:hover {
  color: rgb(20, 146, 255);
}
.tapCss {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 3px;
  cursor: pointer;
}

.tapCss_Choose {
  color: rgb(20, 146, 255);
  border-bottom: 2px solid rgb(20, 146, 255);
}

.tapCss_other {
  margin-left: 40px;
  font-size: 20px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  padding-bottom: 3px;
  cursor: pointer;
}

.hp_body {
  background-color: #fff;
  min-width: 1200px;
  height: 100%;
  position: relative;
}

.hp_container {
  width: 100%;
  background-color: #fff;
}

.hp_header_clean {
  height: 126px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hp_header_white {
  height: 100px;
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 22;
  box-shadow: 5px 5px 5px #00000010;
}

.hp_header_body {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.hp_head_body_left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  .side-title {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    margin-left: 16px;
    border-left: 1px solid #dee0e5;
    padding-left: 16px;
  }
}

.hp_logo {
  width: 227px;
  height: 36px;
}

.hp_logo:hover {
  cursor: pointer;
}

.hp_logo img {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.hp_menu {
  background-color: $--color-primary;
  width: 100%;
  .max-1200 {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}

@font-face {
  font-family: ZKGDH;
  src: url("../font/iconfont_zkgdh.TTF");
}

.hp_menu_btn {
  display: flex;
}

.login_class_b {
  font-size: 1rem;
  color: #409eff;
  background-color: #fff;
  border: 1px #409eff solid;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  border-radius: 2px;
  margin: 0px 20px;
  cursor: pointer;
}

.login_class_a {
  color: white;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  background-color: #409eff;
  border-radius: 2px;
  cursor: pointer;
}

.text-size {
  font-size: 16px;
}

@media (max-width: 1600px) {
  .text-size {
    font-size: 14px;
  }
}
</style>

<style lang="scss" scoped>
.hp_body {
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
  .el-menu > .el-menu-item {
    margin-right: 60px;
  }
  .el-menu > .el-submenu {
    margin-right: 60px;
  }
}
.hp_body :deep(.el-submenu__title i) {
  color: #fff;
}
.hp_body :deep(.el-menu > .el-menu-item) {
  width: 96px;
  border-bottom: none !important;
  text-align: center;
}
.hp_body :deep(.el-menu > .el-menu-item.is-active::after) {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 28px;
  height: 2px;
  width: 38px;
  background-color: #fff;
}
</style>
