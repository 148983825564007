<template>
  <div>
    <div class="banner">
      <div class="max-1200">
        <div class="title">平台简介</div>
        <div class="eng">Platform Introduction</div>
        <div class="des">
          桐乡物流智慧平台是聚焦全产业链上下游高效协同，打造“一核一链四体系”，实现生产资源集成整 合、在线共享、优化配置…
        </div>
      </div>
    </div>
    <div id="about" class="about">
      <div class="max-1200">
        <div class="title">关于我们</div>
        <div class="eng">About Us</div>
        <div class="back">
          <div class="pic">
            <img src="../assets/tongxiang/about_png.png" alt="" />
          </div>
          <div class="text">
            <div v-html="title" />
          </div>
        </div>
      </div>
    </div>
    <div id="privacy" class="privacy">
      <div class="max-1200">
        <div class="title">隐私声明</div>
        <div class="eng">Privacy Statement</div>
        <div v-html="privacy"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import { privacyContract, aboutTitle } from "@/api/tongxiangAPI";

const privacy = ref("");

const getPrivacy = async () => {
  const res = await privacyContract();
  if (res.data.success) {
    privacy.value = res.data.data;
  }
};
getPrivacy();

const title = ref("");

const getAboutTitle = async () => {
  const res = await aboutTitle();
  if (res.data.success) {
    title.value = res.data.data.htmlContent;
  }
};
getAboutTitle();
</script>

<style lang="scss" scoped>
.max-1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 28px;
  font-weight: 600;
  color: #000;
  text-align: center;
}
.eng {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #999;
  text-align: center;
}
.banner {
  width: 100%;
  height: 367px;
  background: url("../assets/tongxiang/platform_banner.png") no-repeat center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-size: 48px;
    font-weight: 600;
    color: #fff;
    line-height: 67px;
    text-align: left;
  }
  .eng {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-top: 6px;
    text-align: left;
  }
  .des {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin-top: 25px;
    width: 600px;
    line-height: 28px;
  }
}
.about {
  background-color: #f4f6fc;
  width: 100%;
  padding: 80px 0;
  .back {
    margin-top: 200px;
    width: 100%;
    height: 414px;
    background-color: $--color-primary;
    position: relative;
    .pic {
      position: absolute;
      bottom: 0;
      left: 70px;
      width: 413px;
      height: 549px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      margin-left: 520px;
      padding: 30px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #ffffff;
    }
  }
}
.privacy {
  background-color: #fff;
  width: 100%;
  padding: 80px 0;
}
</style>
